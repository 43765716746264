

 import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import PageTitle from "../../Components/PageTitle";
import { Chip, TablePagination, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Get_AllUsers } from "../../ReduxToolkit/Slices/UserSlice/User.action";
import { BaseUrl } from "../../ReduxToolkit/Api";
import DummyProfile from "../../Assets/UserDummyImage.png";
import { GetContactusdata } from "../../ReduxToolkit/Slices/ContentManagment/ContentManage.action";
const columns = [
  {
    field: "rowid",
    headerName: "#",
    headerAlign: "center",
    align: "center",
    flex: 0.2,
    minWidth: 80,
    disableColumnMenu: true,
  },
  
  {
    field: "name",
    headerName: "Name",
    headerAlign: "center",
    align: "center",
    height: "100%",
    flex: 1,
    minWidth: 100,
    disableColumnMenu: true,
    renderCell: (params) => (
      <Typography
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          textalign: "center",
          // texttransform:"lowercase"
        }}
      >
        {params?.row?.name ? params?.row?.name : "---"}
      </Typography>
    ),
  },
 
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 100,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: (params) => (
      <Typography
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          textalign: "center",
          textTransform: "lowercase",
        }}
      >
        {params?.row?.email ? params?.row?.email : "--- "}
      </Typography>
    ),
  },
  {
    field: "phno_cc",
    headerName: "Mobile Number",
    flex: 1,
    minWidth: 120,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: (params) => (
      <Typography
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          textalign: "center",
          textTransform: "lowercase",
        }}
      >
        {/* {params?.row?.email ? params?.row?.email : "---"} */}
        {params?.row.phno === null
          ? "--- "
          : params?.row.phno_cc + " " + params?.row.phno}
      </Typography>
    ),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    minWidth: 210,
    headerAlign: "center",
    align: "center",
    disableColumnMenu: true,
    renderCell: (params) => (
      <Typography
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "14px",
          textalign: "center",
          textTransform: "lowercase",
        }}
      >
        {params?.row?.description ? params?.row?.description : "---"}
        
      </Typography>
    ),
  },

 
];

const ContactUs = () => {
  
  const dispatch = useDispatch()

  const [page, setPage] = useState(0)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetContactusdata(newPage * 10));
  }

  useEffect(() => {
    dispatch(GetContactusdata(page * 10));
  }, [])

  let Data = useSelector((Store) => Store?.ContentManage)

  let Rowdata = Data?.contactus?.r?.map((data, i) => ({
    rowid: i + 1+page * 10,
    ...data,
  }))


  return (
    <>
      <PageTitle Title={"Contact Us "} />
      <Box sx={{ height:{xs:"80vh",sm:590}, width: "100%" }}>
        <DataGrid
          rows={Rowdata || []}
          columns={columns}
          disableSelectionOnClick
          disableRowSelectionOnClick
          editMode="false"
          loading={Data?.loading}
          hideFooter
          sx={{
            border: "0px",
            borderRadius: "20px",
            boxShadow: 2,
            textTransform: "capitalize",
            color: "#000",
            fontWeight: "500",
            whiteSpace: "nowrap",
          }}
        />
 
        <TablePagination
          sx={{ mb: "-20px" }}
          component="div"
          count={Data.contactus?.count || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
        />
      </Box>
    </>
  );
};

export default ContactUs;
